@import "../../../assets/scss/colors.scss";

.rest-map-btn {
    position: absolute;
    background: $white1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $black2;
    width: auto;
    font-size: 18px;
    border: 1px solid $blue3;
    border-radius: 42px;
    font-family: MediumSizeFont;
    padding: 5px 20px;
    color: $blue3;
    margin: 0 auto;
    z-index: 1;
    top: 40px;
    right: 90px;
    padding-top: 8px;
}

.map-network-widget-container {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    right: 30px;

    .network-widget-btn {
        color: $blue4;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 5px;
        width: auto;
        height: 44px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border: none;

        .network-btn-title {
            font-family: BookSizeFont;
            font-size: 16px;
            line-height: 44px;
            margin-left: 9px;
        }
    }

    .map-network-opt-list {
        width: 221px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 9px 13px $grey4;
        border-radius: 5px;
        font-size: 12px;
        font-family: BookSizeFont;
        position: absolute;
        right: 0px;
        bottom: 55px;
        padding: 14px 14px 0 14px;

        .map-network-list-cross {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
        .child-opt-holder {
            margin-left: 30px;
            margin-bottom: 15px;
            .color-tile {
                display: flex;
                margin-bottom: 5px;
                .color-tile-block {
                    height: 18px;
                    width: 18px;
                    border-radius: 2px;
                }
                .color-tile-title {
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: MediumSizeFont;
                }
            }
        }
    }
}
