@import "../../../assets/scss/colors.scss";

.resizable-container {
    padding: 15px 0px 0px 0px;
    background-color: $white1;
    cursor: move;
    max-height: 80vh;
    .right-line-resizer {
        top: 0;
        right: 0;
        bottom: 0;
        width: 5px;
        position: absolute;
        border-right: 3px solid transparentgreen;
        cursor: e-resize;
    }
    .bottom-line-resizer {
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        width: 100%;
        height: 5px;
        border-bottom: 3px solid transparent;
        cursor: s-resize;
    }
    &.info-hide {
        height: 50px !important;
        max-width: 50px;
        overflow: inherit !important;
        .right-line-resizer,
        .bottom-line-resizer {
            display: none;
        }
    }
}
