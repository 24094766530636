/* ===== == = === 37.5em (600px) === = == ===== */
$tablet-width-min: 37.5em;
/* ===== == = === 69.5em (1112px) === = == ===== */
$desktop-width-min: 69.5em;

@mixin mobile {
  @media (max-width: #{$tablet-width-min}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width-min}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width-min}) {
    @content;
  }
}
