@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.form-card-container {
    background: $white1;
    left: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.card-shadow-elem.road-search-attr-table-content {
    top: 0px;
    left: 480px;
    position: absolute;
    max-height: 80vh;
    overflow-y: auto;
    padding-top: 10px;

    .header-action-tool {
        position: absolute;
        right: 30px;
        top: 12px;
        cursor: pointer;
        border-radius: 50%;
        background: $white1;
        width: 40px;
        box-shadow: 0 0 0 2px #ebebeb;
        height: 40px;
        zoom: $zoomLevel;
    }
    &.info-hide {
        background: $white1;
        overflow: hidden;

        .rd-search-tbl {
            visibility: hidden;
            opacity: 0;
        }
        .header-action-tool {
            right: 0px;
            left: 0px;
            top: 0px;
            bottom: 0px;
            height: 100%;
            width: 100%;
            box-shadow: none;
            transform: rotate(90deg);
        }
    }
    .rd-search-tbl {
        visibility: visible;
        opacity: 1;
        cursor: auto;
        @include transition2Val(visibility 0.5s linear, opacity 0.5s linear);
        .download-btn-holder {
            padding: 0px 20px 10px 20px;
            zoom: $zoomLevel;
        }
        .table-hover>tbody>tr:hover>* {
            cursor: pointer;
            --bs-table-accent-bg: #A7ACBF1A !important;
        }
    }

    table {
        min-width: 870px;
    }
}
@include customScrollbar(".card-shadow-elem.road-search-attr-table-content");
