.home-container {
    .ol-map {
        margin: 0px;
        min-width: 100vw;
        width: 100vw;
        height: calc(100vh - 84px);
        position: absolute;
        bottom: 0;
        top: 80px;
    }
}