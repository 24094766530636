@import "../../../assets/scss/colors.scss";

.custom-popup-container {
    z-index: 4;
}
.image-player-container {
    width: 407px !important;
    &.double-img {
        width: 814px !important;    
    }
    .custom-popup-wrapper {
        height: auto !important;
    }

    .image-player-section {
        padding: 20px;
        border-bottom: solid 1px $grey2;
        .image-holder-sec {
            display: flex;
            div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                img {
                    width: 367px;
                    height: 284px;
                }
                .info-section-heading {
                    text-align: center;
                }
            }
        }
        .image-action-tool {
            margin-top: 20px;
            .btn-sec-1, .btn-sec-2 {
                color: $grey3;
                font-size: 18px;
                font-family: BookSizeFont;
                margin: 0 15px;
                div {
                    margin: 6px 12px 0px;
                }
                button {
                    width: 27px;
                    height: 26px;
                    background: $white1 0% 0% no-repeat padding-box;
                    border: 1px solid $blue3;
                    border-radius: 3px;
                }
            }
        }
    }
    .info-section {
        padding: 20px;
        .info-section-heading {
            font-family: MediumSizeFont;
            font-size: 18px;
            color: $black1;
            margin-bottom: 4px;
        }
        p {
            margin-bottom: 10px;
            font-family: BookSizeFont;
            color: $grey3;
            font-size: 18px;
        }
    }
}
