@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.uc-form-container {
    width: 100%;
    flex-direction: column;
    zoom: $zoomLevel;
    .d-flex {
        width: 100%;
    }
    .waywize-label-input input {
        width: 300px;
    }
    .uc-form-submit-btn {
        margin-top: 20px;
        margin-bottom: 20px;
        background: $white1 0% 0% no-repeat padding-box;
        border: 2px solid $blue3;
        border-radius: 42px;
        font-size: 20px;
        font-family: MediumSizeFont;
        color: $blue3;
        &:disabled {
            background: $white1 0% 0% no-repeat padding-box !important;
            border: 2px solid $blue3 !important;
        }
    }
}