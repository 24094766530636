@import "../../../assets/scss/colors.scss";

.confirm-alert-popup-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgb(8 8 8 / 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    .confirm-alert-popup-message {
        width: 579px;
        height: 361px;
        max-width: 650px;
        border-radius: 5px;
        padding: 38px 73px;
        background-color: $white1;
        color: $black1;

        h3 {
            font-family: CamptonSemiBold;
            color: $black1;
            font-size: 28px;
            margin: 35px auto 10px;
        }
        p {
            color: $grey3;
            font-family: BookSizeFont;
            font-size: 20px;
        }
        button {
            width: 206px;
            height: 72px;
            background: $white1 0% 0% no-repeat padding-box;
            border-radius: 42px;
            cursor: pointer;
            font-family: MediumSizeFont;
            font-size: 24px;
        }
        .confirm-cancel {
            color: $red1;
            border: 2px solid $red1;
        }
        .confirm-agree {
            color: $blue3;
            border: 2px solid $blue3;
        }
    }
}
