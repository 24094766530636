@import "../../../assets/scss/colors.scss";

.waywize-checkbox-input-container {
    margin: 5px 0px;

    .waywize-checkbox-input-label {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        font-family: BookSizeFont;
        font-size: 12px;
    }

    /* Hide the browser's default checkbox */
    .waywize-checkbox-input-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .waywize-checkbox-checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: $white1;

        border: 1px solid $grey2;
        border-radius: 3px;
    }

    /* When the checkbox is checked, add a blue background */
    .waywize-checkbox-input-label input:checked ~ .waywize-checkbox-checkmark {
        background-color: $blue4;
        border: 1px solid $blue4;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .waywize-checkbox-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .waywize-checkbox-input-label input:checked ~ .waywize-checkbox-checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .waywize-checkbox-input-label .waywize-checkbox-checkmark:after {
        left: 5px;
        top: 0px;
        width: 6px;
        height: 11px;
        border: solid $white1;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
