@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.profile-section-container {
    display: flex;
    .language-selector {
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 0;
        margin-right: 20px;
        button {
            color: $blue3;
            font-size: 14px !important;
            padding: 6px;
            min-width: 96px;
            max-width: 96px;
            span {
                margin: 0px;
            }
            span[class^="ReactFlagsSelect-module_selectValue"] {
                padding: 0px;
            }
            span[class^="ReactFlagsSelect-module_secondaryLabel"] {
                color: $blue3;
                font-size: 14px;
                font-family: BookSizeFont;
            }
        }
        button::after {
            height: auto;
            width: auto;
            border: none;
            content: url("../../../assets/images/caret-2.svg");
        }
        button[aria-expanded="true"]::after {
            transform: rotate(180deg);
        }
        span[class^="ReactFlagsSelect-module_secondaryLabel"] {
            color: $blue3;
            font-size: 14px;
            font-family: BookSizeFont;
        }
    }
    .profile-menu-item-holder {
        max-height: 0px;
        overflow-y: auto;
        width: 158px;
        position: absolute;
        right: 20px;
        top: 80px;
        z-index: 3;
        background-color: $white1;
        @include transitionVal(max-height 0.5s ease-out);
        &.is-active {
            max-height: 950px;
        }
        .profile-menu-item {
            font-size: 14px;
            padding: 12px 14px;
            color: $black1;
            font-family: BookSizeFont;
            border-bottom: solid 1px $blue2;
            .profile-name-title {
                font-family: CamptonSemiBold;
            }
            .profile-name-desg {
                color: $grey2;
            }
            &:hover {
                background-color: $grey1;
            }
        }
    }
    @include customScrollbar('.profile-menu-item-holder');
}
