@import "../../assets/scss/colors.scss";
@import "../../assets/scss/dynamic.scss";

.limit-popup-container {
    max-height: 90vh;
    overflow: auto;

    .custom-popup-wrapper {
        height: unset;
        max-height: unset;
    }

    .waywize-label-input {
        margin: 0 auto;
        display: flex;
        align-items: center;
        input {
            width: 180px;
        }
    }

    .no-records-txt {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: MediumSizeFont;
    }
    .limit-form-submit-btn {
        margin-top: 20px;
        margin-bottom: 20px;
        background: $white1 0% 0% no-repeat padding-box;
        border: 2px solid $blue3;
        border-radius: 42px;
        font-size: 20px;
        font-family: MediumSizeFont;
        color: $blue3;
        &:disabled {
            background: $white1 0% 0% no-repeat padding-box !important;
            border: 2px solid $blue3 !important;
        }
    }
}

@include customScrollbar('.limit-popup-container');
