@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.waywize-label-input {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 20px;
    margin: 0 auto 13px auto;
    font-family: MediumSizeFont;
    &.error_border {
        input {
            border: solid 1px $red3;
        }
        .err-msg-span {
            font-family: BookSizeFont;
            margin-top: 12px;
            font-size: 16px;
            color: $red3;
        }
    }
    .label-info {
        position: relative;
        .ellipsised-label {
            white-space: nowrap; 
            width: 300px; 
            overflow: hidden;
            text-overflow: ellipsis;
            &.help-txt-also {
                width: 265px;
            }
        }  
        .help-text-icon {
            margin-left: 5px;
            margin-right: 5px;
            cursor: pointer;
        }
        .help-text-content {
            position: absolute;
            z-index: 3;
            background: $white1;
            bottom: 35px;
            border-radius: 3px;
            padding: 5px 10px;
            border: 2px solid #A7ACBF;
            font-size: 12px;
            font-family: MediumSizeFont;
        }
    }

    input[type="date"] {
        position: relative;
    }

    input[type="date"]:after {
        content: url("./Calendar.svg");
        position: absolute;
        top: 15px;
        bottom: 0px;
        right: 22px;
    }

    // input[type="date"]::-webkit-calendar-picker-indicator {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     width: auto;
    //     height: auto;
    //     color: transparent;
    //     background: transparent;
    // }

    input {
        width: 380px;
        height: 60px;
        padding: 18px 20px;
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 5px;
        color: $blue3;
        border: 2px solid $grey2;
        &:focus-visible,
        &:focus,
        &:active {
            @include boxShadow(0px 3px 9px $lightBlue1);
            border: 2px solid $blue5;
            outline-color: $blue5;
        }
        &:disabled {
            background: $grey1;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
