@import "../../assets/scss/colors.scss";
@import "../../assets/scss/screen.scss";

#app {
    background: url("./../../assets/images/welcome-bg.png") no-repeat;
    background-size: cover;

    .welcome-container {
        color: $green1;
        text-align: center;
        min-height: 100vh;

        .log-in-button {
            color: $white2;
            background: $green7;
            border-radius: 36px;
            font-family: CamptonSemiBold;
            font-size: 24px;
            cursor: pointer;
            text-decoration: none;
            padding: 15px 64px;
            transition: 0.5s;
            border: none;
            &:hover {
                color: $white1;
                background: $green6;
            }
        }

        .welcome-h1 {
            margin-top: 25vh;
            color: $white1;
            position: relative;
            font-size: 64px;
            font-family: MediumSizeFont;
            span {
                color: $green7;
            }
            .mode-value {
                font-size: 15px;
                position: absolute;
                top: 0;
                color: $white1;
            }
            @include mobile {
                font-size: 35px;
                margin-top: 10vh;
            }
        }
        .welcome-msg {
            width: 100%;
            max-width: 744px;
            height: 58px;
            text-align: center;
            color: $white1;
            margin: 19px auto 80px auto;
            font-size: 24px;
            letter-spacing: 0px;
            font-family: BookSizeFont;
            
            @include mobile {
                font-size: 20px;
            }
        }

        .rmms-logo {
            margin-top: 20px;
            width: 200px;
            height: 40px;
            opacity: 1;
        }

        .bottom-part {
            width: 100%;
            color: $white1;
            font-family: BookSizeFont;
            font-size: 16px;
            margin-top: 20vh;
            .term-service {
                color: $white1;
                text-decoration: none;
                &:hover {
                    color: $green1;
                }
            }
        }
    }
}
