@import "../../../assets/scss/dynamic.scss";

.map-input-divs {
    position: absolute;
    top: 0px;
    left: 0px;
    >.moveable-window-container {
        left: 70px !important;
        &.hide-info-tile {
            left: 0px !important;
        }
    }
    // .moveable-window-container.hide-info-tile:nth-child(1) {
    //     top: calc(118px) !important;
    // }
    // .moveable-window-container.hide-info-tile:nth-child(2) {
    //     top: calc(60px + 118px) !important;
    // }
    // .moveable-window-container.hide-info-tile:nth-child(3) {
    //     top: calc(60px + 178px) !important;
    // }
    // .moveable-window-container.hide-info-tile:nth-child(4) {
    //     top: calc(60px + 238px) !important;
    // }
    // .moveable-window-container.hide-info-tile:nth-child(5) {
    //     top: calc(60px + 298px) !important;
    // }
    // .moveable-window-container.hide-info-tile:nth-child(6) {
    //     top: calc(60px + 358px) !important;
    // }
}
.moveable-window-container {
    min-width: 470px;
    &.strategy-moveable-comp {
        .moveable-window-content {
            overflow-y: auto;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .form-card-container {
        padding-top: 10px;
        width: 100%;
        zoom: $zoomLevel;
        label, .waywize-select-input-container {
            width: 100%;
            input, .waywize-react-select.__control {
                width: 100%;
            }
        }
        .waywize-button {
            height: 60px;
            width: 100%;
        }
    }
}