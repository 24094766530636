@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.waywize-label-textarea {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 20px;
    margin: 0 auto 13px auto;
    textarea {
        width: 370px;
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 5px;
        color: $blue3;
        padding: 18px 20px;
        border: 2px solid $grey2;

        &:focus-visible,
        &:focus,
        &:active {
            @include boxShadow(0px 3px 9px $lightBlue1);
            border: 2px solid $blue5;
            outline-color: $blue5;
        }
        &:disabled {
            background: $grey1;
        }
    }
}
