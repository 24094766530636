@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.form-card-container {
    background: $white1;
    left: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.card-shadow-elem.table-content,
.card-shadow-elem.chart-and-table {
    top: 0px;
    position: absolute;
}
.card-shadow-elem.chart-and-table {
    left: 475px;
    display: flex;
    padding-top: 10px;
    .header-action-tool {
        position: absolute;
        right: 30px;
        top: 12px;
        cursor: pointer;
        border-radius: 50%;
        background: $white1;
        width: 40px;
        box-shadow: 0 0 0 2px #ebebeb;
        height: 40px;
        zoom: $zoomLevel;
    }
    .chart-content-holder,
    .table-info-holder {
        visibility: visible;
        opacity: 1;
        @include transition2Val(visibility 0.5s linear, opacity 0.5s linear);
    }
    &.info-hide {
        .chart-content-holder,
        .table-info-holder {
            visibility: hidden;
            opacity: 0;
        }
        .header-action-tool {
            right: 0px;
            left: 0px;
            top: 0px;
            bottom: 0px;
            height: 100%;
            width: 100%;
            box-shadow: none;
            transform: rotate(90deg);
        }
    }
    .chart-content-holder {
        max-width: 442px;
        min-width: 370px;
        zoom: $zoomLevel;
        padding: 0 20px;
        cursor: auto;
        overflow-y: auto;
        padding-bottom: 50px;
    }
    @include customScrollbar('.chart-content-holder');
    .chart-content-header-title {
        font-family: MediumSizeFont;
        font-size: 20px;
        color: $black1;
        padding: 20px 0px;
    }
    .table-info-holder {
        max-height: 80vh;
        overflow-y: auto;
        cursor: auto;
        .download-btn-holder {
            padding: 0px 20px 10px 20px;
            zoom: $zoomLevel
        }
        table tbody tr {
            cursor: pointer;
        }
    }
    @include customScrollbar('.table-info-holder');
}
.card-shadow-elem.table-content {
    overflow-y: auto;
    left: 820px;
    min-width: 800px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
