@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.moveable-window-container {
    position: absolute;
    top: 118px;
    z-index: 3;
    background: $white1;
    left: 70px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    font-family: "MediumSizeFont";
    @include boxShadow(0px 9px 13px #c6c6ce33);
    border-radius: 5px;
    cursor: move;
    min-width: 300px;
    max-height: 80vh;
    max-width: 3000px;
    &.some-transition {
        @include transition2Val(max-height 0.5s ease-out, max-width 0.5s ease-out);
    }
    &.full-transition {
        @include transition4Val(max-height 0.5s ease-out, max-width 0.5s ease-out, left 0.5s linear, top 0.5s linear);
    }
    .moveable-window-container-tooltip {
        display: none;
    }
    &.hide-info-tile {
        max-height: 51px;
        max-width: 300px;
        min-width: 0px;
        box-shadow: 3px 3px 3px $black2;
        border-radius: 0px 5px 5px 0px;
        cursor: pointer;
        z-index: 1;
        .moveable-window-container-tooltip {
            display: block;
            opacity: 0;
            max-width: 0px;
            .elipsis-title {
                padding: 7px 8px 4px;
                overflow: hidden;
            }
        }
        &:hover {
            z-index: 4;
            .moveable-window-container-tooltip {
                opacity: 1;
                position: absolute;
                left: 61px;
                min-width: auto;
                background: $black1;
                color: $white1;
                border-radius: 5px;
                opacity: 0.7;
                white-space: nowrap;
                display: table;
                &:before {
                    content: "";
                    bottom: 0px;
                    left: -4px;
                    width: 10px;
                    height: 10px;
                    transform: rotate(130deg);
                    box-shadow: 3px 3px 3px $shadow1;
                    top: 40%;
                    position: absolute;
                    background-color: $black1;
                    opacity: 0.7;
                }
            }
        }

        .moveable-window-header-title {
            display: none;
        }
        .moveable-window-header-section {
            border: none;
            height: 51px;
            width: 51px;
            padding: 0px;
        }
    }

    .moveable-window-header-section {
        height: 68px;
        width: 100%;
        padding: 20px 30px;
        border-bottom: solid 1px $grey2;
        zoom: $zoomLevel;

        .moveable-window-header-title {
            font-family: MediumSizeFont;
            font-size: 20px;
            color: $black1;
        }
        .moveable-window-header-action-tool {

            .svg-img-holder {
                position: absolute;
                right: 30px;
                top: 15px;
                width: 50px;
                transition: 0.5s;
                background: transparent;
                padding: 10px 0px;
                cursor: initial;
                .toggle-btn {
                    cursor: pointer;
                    margin: 0 auto;
                }
                .cross-btn {
                    cursor: pointer;
                }
                &.info-hide {
                    right: 0px;
                    top: 0px;
                    bottom: 0px;
                    cursor: pointer;
                    .cross-btn {
                        display: none;
                    }
                }
            }
        }
    }
    .moveable-window-content {
        cursor: auto;
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 10px;
        visibility: visible;
        opacity: 1;
        overflow-y: auto;
        @include transition3Val(visibility 1s linear, opacity 1s linear, max-width 0.5s ease-out);
        &.hide-info-tile {
            visibility: hidden;
            opacity: 0;
            overflow: hidden;
            max-width: 8px;
            .card-shadow-elem.chart-and-table,
            .card-shadow-elem.plan-sec-table-content,
            .card-shadow-elem.road-search-attr-table-content {
                display: none
            }
        }
    }
    @include customScrollbar('.moveable-window-content');
}
