@import '../../../assets/scss/colors.scss';

.waywize-button {
    border: none;
    text-transform: capitalize;
    border-radius: 40px;
    font-size: 24px;
    padding: 5px 20px;
    color: $white1;
    margin: 0 auto;
    background-color: $green1;
}