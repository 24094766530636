@import "../../assets/scss/colors.scss";

.users-container {
    overflow-y: auto;
    &.route-ui {
        height: 100vh;
    }
    .users-cont-heading {
        padding: 20px 68px;
        font-size: 25px;
        font-family: MediumSizeFont;
    }
    .users-headin-revision {
        font-size: 20px;
    }
    .users-context {
        .h3-fetching-text {
            font-family: MediumSizeFont;
            font-size: 20px;
            color: $black1;
        }
        .users-table-title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        .users-context-save {
            border-radius: 5px;
            height: 42px;
            margin: 0px;
            background: $white1 0% 0% no-repeat padding-box;
            border: 2px solid $blue3;
            border-radius: 42px;
            font-size: 20px;
            font-family: MediumSizeFont;
            color: $blue3;
        }
    }
}