@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/dynamic.scss";

.strategy-form-container {
    width: 100%;
    flex-direction: column;
    zoom: $zoomLevel;
    .d-flex {
        width: 100%;
    }
    .independent-div {
        label {
            margin-left: 22px;
        }
    }
    .blank-div {
        min-width: 300px;
        margin: 0 auto 13px auto;
        opacity: 0;
        visibility: none;
    }
    .waywize-select-input-container.select-plan-box {
        width: 85%;
        .waywize-react-select.__control {
            width: 725px;
            max-width: 725px;
        }
    }
    .single-input-div {
        label, .waywize-select-input-container  {
            margin-left: 35px;
            margin-right: 35px;
        }
    }
    .waywize-react-select.__menu-list {
        max-height: 150px;
    }
    .waywize-label-textarea,
    .waywize-checkbox-input-container {
        margin: 0 auto 13px auto;
        padding: 0px;
        label {
            font-size: 20px;
            width: 300px;
        }
    }
    .waywize-select-input-container {
        margin: 0 auto 13px auto;
        padding: 0px;
        label {
            margin-left: 0px;
            margin-bottom: 5px;
        }
        .waywize-react-select.__control {
            max-width: 300px;
        }
    }
    .waywize-label-textarea {
        width: 300px;
    }
    .waywize-label-input input {
        width: 300px;
    }
    .strategy-form-submit-btn {
        margin-top: 20px;
        background: $white1 0% 0% no-repeat padding-box;
        border: 2px solid $blue3;
        border-radius: 42px;
        font-size: 20px;
        font-family: MediumSizeFont;
        color: $blue3;
        &:disabled {
            background: $white1 0% 0% no-repeat padding-box !important;
            border: 2px solid $blue3 !important;
        }
    }
}