@import "../../assets/scss/dynamic.scss";
@import "../../assets/scss/colors.scss";
@import "../../assets/scss/screen.scss";

.navbar-header {
    background-color: $blue3 !important;
    .nav-box-container {
        height: 80px;
        .logo-section {
            cursor: pointer;
            .logo-title {
                font-size: 30px;
                font-family: CamptonSemiBold;
                padding: 0 33px 0 20px;
                line-height: 30px;
                border-right: solid 1px;
                vertical-align: middle;
                box-sizing: border-box;
                text-align: center;
                height: 30px;
                padding-top: 3px;
                margin-right: 20px;
                .nav-mode-value {
                    font-size: 10px;
                    position: absolute;
                    top: 6px;
                    color: $white1;
                }
            }
        }

        .menu-list-holder {
            position: absolute;
            z-index: 9;
            background: $white1;
            height: 100vh;
            top: 0;
            left: -350px;
            padding: 10px;
            width: 350px;
            @include transitionVal(left 0.5s ease-out);
            &.is-active {
                left: 0px;
            }

            .nav-bar-menu-cls-btn {
                border: none;
                width: 100%;
                text-decoration: none;
                outline: none;
                height: 50px;
            }
            .navbar-mobile-menu-item {
                width: 100%;
                display: flex;
                text-decoration: none;
                font-size: 20px;
                height: 40px;
                color: $black1;
                cursor: pointer;
                align-items: center;
                &:hover {
                    background-color: $grey2;
                }
            }
        }
        .navbar-menu-toggle {
            display: none;
            @include tablet {
                display: block;
            }
        }
        .nav-menu-list {
            font-family: BookSizeFont;
            height: 80px;
            .nav-menu-list-item {
                padding: 0 32px;
                font-size: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;
                &.active-op {
                    color: $green7;
                    border-bottom: solid 3px $green7;
                }
            }
            @include tablet {
                display: none !important;
            }
        }

        #profile-section {
            margin-right: 30px;
            outline: none;
            box-shadow: none;
            display: flex;
            align-items: center;
        }
        #profile-section::after {
            position: absolute;
            border: none;
            height: 100%;
            align-items: center;
            vertical-align: middle;
            top: -2px;
            bottom: 0;
            display: flex;
            right: 27px;
            content: url('../../assets/images/caret.svg');
        }
    }
}
