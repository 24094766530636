@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.update-multiple {
    width: auto;
    font-size: 15px;
    background: $white1 0% 0% no-repeat padding-box;
    border: 2px solid $blue3;
    border-radius: 42px;
    color: $blue3;
    .update-multiple-txt {
        margin-top: 2px;
        margin-left: 4px;
    }
}
table {
    border-collapse: collapse;
    overflow: hidden;
    width: auto;
    zoom: $zoomLevel;
    .action-btn-view,
    .action-btn-edit,
    .action-btn-delete {
        height: 32px;
        width: 32px;
        padding: 0px;
        margin: 0 5px;
        border: none;
        background-color: transparent;
    }
    margin-bottom: 30px !important;
}

.table>:not(:first-child) {
    border-top: none !important;
}

.table-hover>tbody>tr:hover>*,
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: none !important;
    color: $grey3;
}

table,
th,
td {
    border: 1px solid $grey2;
}

table {
    thead {
        width: 523px;
        height: 43px;
        background: $blue3 0% 0% no-repeat padding-box;
        tr th {
            font-family: BookSizeFont;
            color: $white1;
            font-size: 20px;
            font-weight: unset;
            padding: 10px 20px !important;
            vertical-align: middle;
            text-align: center;
        }
    }
    tbody {
        tr {
            vertical-align: middle;
            &.active-rw {
                background-color: $grey1;
            }
            td {
                font-size: 18px;
                font-family: BookSizeFont;
                padding: 10px 20px !important;
                display: table-cell !important;
                text-align: center;
            }
        }
    }
}

.download-btn-holder {
    cursor: move;
    button {
        font-family: MediumSizeFont;
        background: $white1 0% 0% no-repeat padding-box;
        border: 2px solid $blue3;
        border-radius: 42px;
        color: $blue3;
        font-size: 20px;
        cursor: pointer;
    }
}
