@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.maintenance-container {
    .end-flex {
        zoom: $zoomLevel;
    }
    .add-strategy-btn {
        border: none;
        background-color: transparent;
        text-align: center;
        padding: 0;
        font-size: 20px;
        font-family: MediumSizeFont;
        color: $blue3;
        height: 24px;
        margin: auto 0;
    }
    .h3-fetching-text {
        font-family: MediumSizeFont;
        font-size: 20px;
        color: $black1;
    }
    .m-plan-table {
        table {
            display: block;
            max-width: 800px;
            overflow: auto;
            .custom-prefix-select__control {
                width: 183px;
            }
        }
    }
    .custom-popup-sub-container {
        width: 800px !important;
        .custom-popup-wrapper {
            padding: 16px;
            height: auto;
            max-height: 80vh;
        }
    }

    .multi-edit-popup {
        .custom-popup-wrapper {
            min-height: 250px;
        }
    }
    .card-shadow-elem.plan-sec-table-content {
        top: 0px;
        left: 780px;
        position: absolute;
        max-height: 80vh;
        width: 910px;
        overflow-y: auto;
        background-color: $white1;
        display: flex;
        cursor: move;

        .add-new-holder {
            display: flex;
        }
        .header-action-tool {
            position: absolute;
            right: 30px;
            top: 12px;
            cursor: pointer;
            border-radius: 50%;
            background: $white1;
            width: 40px;
            box-shadow: 0 0 0 2px #ebebeb;
            height: 40px;
            zoom: $zoomLevel;
        }
        &.info-hide {
            background: $white1;
            overflow: hidden;

            .add-new-holder,
            .chart-content-holder {
                display: none;
            }
            .plan-sec-table {
                visibility: hidden;
                opacity: 0;
                display: none;
            }
            .header-action-tool {
                right: 0px;
                left: 0px;
                top: 0px;
                bottom: 0px;
                height: 100%;
                width: 100%;
                box-shadow: none;
                transform: rotate(90deg);
            }
        }
        .chart-content-holder {
            max-width: 300px;
            zoom: $zoomLevel;
            cursor: auto;
            .bar-chart-div {
                padding: 20px;
                height: 330px;
            }
        }
        .chart-content-header-title {
            font-family: MediumSizeFont;
            font-size: 20px;
            color: $black1;
            padding: 5px 20px 20px 20px;
        }
        .plan-sec-table {
            visibility: visible;
            opacity: 1;
            cursor: auto;
            zoom: $zoomLevel;
            @include transition2Val(visibility 0.5s linear, opacity 0.5s linear);
            table tbody tr {
                cursor: pointer;
            }
            .download-btn-holder {
                padding: 0px 20px 10px 0px;
                button {
                    font-size: 15px;
                }
            }
        }

        table {
            min-width: 870px;
            // max-width: 870px;
            // max-height: calc(70vh - 64px);
            overflow: auto;
            display: block;
            .waywize-multi-select-input-label {
                margin: 0px;
                text-align: left;
            }
            .custom-prefix-select__control {
                min-width: 300px;
            }
            .custom-prefix-select__menu {
                .custom-prefix-select__menu-list {
                    padding: 0px;
                    .custom-prefix-select__option {
                        height: 50px;
                        font-family: BookSizeFont;
                        font-size: 16px;
                        color: $grey5;
                        background-color: $white1;
                        border-bottom: 1px solid $grey2;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
        
                        &.__option--is-focused {
                            background: #eef0ff 0% 0% no-repeat padding-box;
                        }
                        &.__option--is-selected {
                            color: $blue3;
                            background: #eef0ff 0% 0% no-repeat padding-box;
                        }
                    }
                }
                @include customScrollbar('.custom-prefix-select__menu-list');
            }
        }
    }
    @include customScrollbar(".card-shadow-elem.plan-sec-table-content");
    @include customScrollbar(".plan-sec-table table");
    @include customScrollbar(".m-plan-table table");
}
