@import '../../../assets/scss/colors.scss';

.waywize-table-pagination {
    padding: 5px 0 30px 0;
    margin: 0 auto;
    button {
        width: 32px;
        height: 29px;
        outline: none;
        background: $white1 0% 0% no-repeat padding-box;
        border: 1px solid $blue3;
        border-radius: 3px;
        &:disabled {
            background: $white1 0% 0% no-repeat padding-box !important;
            border: 1px solid $blue3 !important;
        }
    }
    select {
        height: 30px;
    }
    .go-to-page-sec {
        .waywize-label-input {
            margin: 0px;
            input {
                width: 67px;
                height: 29px;
                padding: 0px 10px;
                margin: 0px;
                background: $white1 0% 0% no-repeat padding-box !important;
                border: 1px solid $blue3 !important;
                border-radius: 3px;
            }
        }
    }
}