@use "./index" as *;

:export {
    // Define Colors
    black1: $black1;
    black2: $black2;
    black3: $black3;
    black4: $black4;
    richBlack: $richBlack;
    teal: $teal;
    white1: $white1;
    white2: $white2;
    blue1: $blue1;
    blue2: $blue2;
    blue3: $blue3;
    blue4: $blue4;
    blue5: $blue5;
    blue6: $blue6;
    blue7: $blue7;
    blue8: $blue8;
    lightBlue1: $lightBlue1;
    lightBlue2: $lightBlue2;
    lightBlue3: $lightBlue3;
    lightBlue4: $lightBlue4;
    lightBlue5: $lightBlue5;
    lightBlue6: $lightBlue6;
    lightBlue7: $lightBlue7;
    lightBlue8: $lightBlue8;
    green1: $green1;
    green2: $green2;
    green3: $green3;
    green4: $green4;
    green5: $green5;
    green6: $green6;
    green7: $green7;
    green8: $green8;
    green9: $green9;
    green10: $green10;
    green11: $green11;
    green12: $green12;
    lightGreen: $lightGreen;
    lightGreen2: $lightGreen2;
    lightGreen3: $lightGreen3;
    lightGreen4: $lightGreen4;
    lightGreen5: $lightGreen5;
    lightGreen6: $lightGreen6;
    lightGreen7: $lightGreen7;
    lightGreen8: $lightGreen8;
    lightGreen9: $lightGreen9;
    lightGreen10: $lightGreen10;
    darkGreen1: $darkGreen1;
    lightCyan: $lightCyan;
    red1: $red1;
    red2: $red2;
    red3: $red3;
    lightRed1: $lightRed1;
    lightRed2: $lightRed2;
    pink1: $pink1;
    pink2: $pink2;
    pink3: $pink3;
    pink4: $pink4;
    grey1: $grey1;
    grey2: $grey2;
    grey3: $grey3;
    grey4: $grey4;
    grey5: $grey5;
    grey6: $grey6;
    yellow1: $yellow1;
    yellow2: $yellow2;
    yellow3: $yellow3;
    yellow4: $yellow4;
    purple1: $purple1;
    purple2: $purple2;
    purple3: $purple3;
    purple4: $purple4;
    purple5: $purple5;
    purple6: $purple6;
    purple7: $purple7;
    purple8: $purple8;
    purple9: $purple9;
    purple10: $purple10;
    aqua: $aqua;
    waterSpout: $waterSpout;
    orange1: $orange1;
    orange2: $orange2;
    orange3: $orange3;
    shadow1: $shadow1;

    // Define Screen Sizes
    mobile: $tablet-width-min;
    tablet: $desktop-width-min;

    zoomLevel: $zoomLevel;
}

$sides: (top, bottom, left, right);

@for $i from 0 through 99 {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            margin-#{$side}: #{$i}px;
        }
        .p#{str-slice($side, 0, 1)}-#{$i} {
            padding-#{$side}: #{$i}px;
        }
    }
}

.m-auto {
    margin: 0 auto;
}

.d-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.between-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.evenly-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.around-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.end-flex {
    display: flex;
    align-items: center;
    justify-content: end;
}

.f-end-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.f-column {
    flex-direction: column;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.no-wrap {
    white-space: nowrap;
}

button:focus {
    outline: none !important;
}

button:disabled {
    cursor: not-allowed;
    background-color: $grey2 !important;
    border: none !important;
    &:hover {
        background-color: $grey2 !important;
        border: none !important;
    }
}

input::placeholder {
    font-family: "MediumSizeFont";
}
input::-webkit-input-placeholder {
    font-family: "MediumSizeFont";
}
input::-moz-placeholder {
    font-family: "MediumSizeFont";
}
input:-ms-input-placeholder {
    font-family: "MediumSizeFont";
}
input:-moz-placeholder {
    font-family: "MediumSizeFont";
}

fieldset {
    border-width: 1px;
    border-style: groove;
    border-color: $grey2;
    border-image: initial;
    width: 100%;
    padding: 10px 30px;
    legend {
        float: unset;
        width: unset;
        padding-inline-start: 2px;
        padding-inline-end: 2px;
    }
}

.card-shadow-elem {
    background: $white1 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 13px $grey4;
    border-radius: 5px;
}