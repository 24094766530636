@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.form-card-container {
    background: $white1;
    left: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    .optional-input-holder {
        @include boxShadow(0px 0px 20px 2px #c6c6ce33);
        border-radius: 5px;
        padding: 10px;
    }
    .or-div {
        margin: 0 auto;
        padding-top: 5px;
        @include boxShadow(0px 0px 20px 2px $blue6);
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}
