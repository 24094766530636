@import "../../../assets/scss/colors.scss";

.map-widget-container {
    margin-top: 38px;
    max-width: 38px;
    position: absolute;
    top: 0;
    z-index: 1;
    right: 30px;
    
    .layer-btn {
        color: $blue4;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 5px;
    }
    .layer-btn,
    .map-zoom-in-btn,
    .map-zoom-out-btn {
        height: 38px;
        width: 38px;
        padding: 0px;
        background-color: $white1;
    }
    .map-zoom-in-btn {
        border-radius: 0px;
        border-bottom: 0.4px solid #d0d2e1;
    }
    .map-zoom-out-btn {
        border-radius: 0px;
        border-top: 0.4px solid #d0d2e1;
    }
    .zoom-in-out-btn {
        overflow: hidden;
        border-radius: 5px;
        margin-top: 10px;
    }
    .map-layer-list-notch {
        position: absolute;
        width: 17px;
        height: 17px;
        transform: matrix(-0.73, 0.68, -0.68, -0.73, 0, 0);
        background: $white1 0% 0% no-repeat padding-box;
        z-index: 3;
        opacity: 1;
        right: 47px;
        top: 12px;
    }
    .map-layer-list {
        width: 124px;
        height: 351px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 3px 3px 2px #0000001A;
        border-radius: 5px;
        overflow-y: auto;
        position: absolute;
        right: 55px;
        padding: 13px;
        top: 0px;
        .map-layer-list-item {
            cursor: pointer;
            img {
                width: 92px;
                height: 44px;
            }
            &.active-mode {
                img {
                    border: 1px solid $blue5;
                    padding: 1px;
                }
            }
            .map-layer-list-item-name {
                font-family: MediumSizeFont;
                margin-top: 7px;
                margin-bottom: 16px;
                font-size: 12px;
            }
        }
    }
    .map-layer-list::-webkit-scrollbar {
        display: none;
    }
}

// Style for resizer button
.ol-full-screen.ol-control {
    right: 30px;
    top: 180px;
    padding: 0px;
    button {
        height: 38px;
        width: 38px;
        border-radius: 0px;
        text-align: center;
        padding: 0px;
        background-color: $white1;
        color: $blue4;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 5px;
        border: none;
        font-size: 24px;
    }
}
