$black1: #000000;
$black2: #00000029;
$black3: #081D58;
$black4: #00168B;
$richBlack: #004040;
$teal: #008080;
$white1: #ffffff;
$white2: #fbfbff;
$blue1: #8087eb;
$blue2: #F1F3FF;
$blue3: #172679;
$blue4: #2438a8;
$blue5: #2e4af2;
$blue6: #9eabed;
$blue7: #2940C1;
$blue8: #334AE1;
$lightBlue1: #828dcb75;
$lightBlue2: #90ACF7;
$lightBlue3: #215CF8;
$lightBlue4: #225EA8;
$lightBlue5: #1D91C0;
$lightBlue6: #24A9DF;
$lightBlue7: #4BCBFF;
$lightBlue8: #35CADC;
$green1: #05f29c;
$green2: #03784f;
$green3: #69F7C2;
$green4: #05b573;
$green5: #9cfad6;
$green6: #08e292;
$green7: #05F29B;
$green8: #41B6C4;
$green9: #005824;
$green10: #238B45;
$green11: #03C780;
$green12: #C3FCE6;
$lightGreen: #05F2C6;
$lightGreen2: #00bf79;
$lightGreen3: #E5F5F9;
$lightGreen4: #91CF60;
$lightGreen5: #C8FA9C;
$lightGreen6: #95F63F;
$lightGreen7: #7FCDBB;
$lightGreen8: #B9E2A2;
$lightGreen9: #C7E9B4;
$lightGreen10: #00BDBD;
$darkGreen1: #1A9850;
$lightCyan: #66ffff;
$red1: #FF313A;
$red2: #FFEBEC;
$red3: #D73027;
$lightRed1: #993404;
$lightRed2: #BD0026;
$pink1: #980043;
$pink2: #DD1C77;
$pink3: #DF65B0;
$pink4: #D7B5D8;
$grey1: #C6C6CE3B;
$grey2: #A7ACBF;
$grey3: #5a5a5a;
$grey4: #c6c6ce33;
$grey5: #5c6171;
$grey6: #394157;
$yellow1: #ffff00;
$yellow2: #FEE08B;
$yellow3: #EDF8B1;
$yellow4: #FFFFD9;
$purple1: #37026B;
$purple2: #480B84;
$purple3: #5704AA;
$purple4: #690DC5; 
$purple5: #7C16E2; 
$purple6: #9E56E6;
$purple7: #C58DFE;
$purple8: #E1C4FF;
$purple9: #EFDFFF;
$purple10: #F8F2FF;
$aqua: #00FFFF;
$waterSpout: #96ffff;
$orange1: #E6550D;
$orange2: #FC8D59;
$orange3: #FDAE6B;
$shadow1: #060f3b4d;