@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.stg-btn-holder {
    zoom: $zoomLevel;
}

.unit-cost-popup {
    width: 800px !important;
    min-height: 84vh;
    .custom-popup-wrapper {
        max-height: 80vh;
        height: auto;
        .row {
            width: 100%;
        }
    }
}
.unit-cost-btn {
    height: 42px;
    margin: 0px;
    background: $white1 0% 0% no-repeat padding-box;
    border: 2px solid $blue3;
    border-radius: 42px;
    font-size: 20px;
    font-family: MediumSizeFont;
    color: $blue3;
    line-height: 35px;
}
.add-strategy-btn {
    border: none;
    background-color: transparent;
    text-align: center;
    padding: 0;
    font-size: 20px;
    font-family: MediumSizeFont;
    color: $blue3;
    height: 24px;
    margin: auto 0;
}
.h3-fetching-text {
    font-family: MediumSizeFont;
    font-size: 20px;
    color: $black1;
}