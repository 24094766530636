@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.waywize-toggle-switch-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    .toggle {
        --width: 70px;
        --height: calc(var(--width) / 2.1);
        margin: 0px;
        position: relative;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: var(--height);
        cursor: pointer;
    }

    .toggle input {
        display: none;
    }

    .toggle .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);
        background-color: #ededed;
        transition: all 0.4s ease-in-out;
    }

    .toggle .slider::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: calc(var(--height) - 3px);
        height: calc(var(--height) - 3px);
        border-radius: 50%;
        background-color: $white1;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.4s ease-in-out;
    }

    .toggle input:checked + .slider {
        background-color: #02d256;
    }

    .toggle input:checked + .slider::before {
        transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-family: sans-serif;
        transition: all 0.4s ease-in-out;
        overflow: hidden;
    }

    .toggle .labels::after {
        content: attr(data-off);
        position: absolute;
        right: 5px;
        color: #cdcdcd;
        opacity: 1;
        font-size: 15px;
        font-weight: 700;
        transition: all 0.4s ease-in-out;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .toggle .labels::before {
        content: attr(data-on);
        position: absolute;
        left: calc(var(--height) - var(--width) + 10px);
        color: #03bb4b;
        opacity: 0;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
        transition: all 0.4s ease-in-out;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .toggle input:checked ~ .labels::after {
        opacity: 0;
        transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle input:checked ~ .labels::before {
        opacity: 1;
        font-size: 15px;
        font-weight: 700;
        transform: translateX(calc(var(--width) - var(--height)));
    }
}
